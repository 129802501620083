.fade-enter {
    opacity: 0;
}
.fade-enter-active {
    opacity: 1;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    transition: opacity 500ms;
}
.fade-exit {
    opacity: 1;
}
.fade-exit-active {
    opacity: 0;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    transition: opacity 500ms;
}
