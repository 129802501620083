.js-nav-item {
    padding: 1.5rem;
    font-size: 1.3rem;
    color: white;
}

@media (min-resolution: 2dppx) {
    .js-nav-item {
        padding: 1rem;
    }
}