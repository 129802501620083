.remove-button {
    position: relative;
    width: 34px;
    height: 34px;
    opacity: 1;
}
.remove-button:hover {
    opacity: 0.6;
    cursor: pointer;
}
.remove-button:before, .remove-button:after {
    position: absolute;
    left: 17px;
    top: 3px;
    content: ' ';
    height: 34px;
    width: 4px;
    background-color: #302F2F;
}
.remove-button:before {
    transform: rotate(45deg);
}
.remove-button:after {
    transform: rotate(-45deg);
}
