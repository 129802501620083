.screen-wrapper {
    display: flex;
    justify-content: center;
}


.screen {
    padding-top: 5REM;
    min-height: calc(100vh - 5rem);
    height: 100%;
    max-width: 1000px;
}

.footer {
    display: flex;
    justify-content: center;
    width: 100%;
    padding-top: 7REM;
    padding-bottom: 3REM;
}

.footer img {
    width: 180px;
    height: 65px;
}

@media (min-width: 1000px) {
    .screen{
        width: 1000px
    }
}

@media (max-width: 1000px) {
    .screen {
        width: 100vw;
        min-width: 320px;
    }
}
