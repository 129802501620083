.program {
    background-color: white;
    background-repeat: no-repeat;
    background-position-x: center;
    background-size: contain;
}

.program-header {
    padding-top: 2rem;
}

.program-list ul {
    display: flex;
    flex-direction: column;
    align-items: start;
    list-style-type: none;
    width: fit-content;
    margin: 0;
    padding: 0;
}

.program-list {
    display: flex;
    justify-content: center;
    width: 100%;
}

.program .sub-title {
    margin-top: 40px;
    text-align: center;
    margin-bottom: 10px;
    text-transform: unset;
}

.program .rsvp-wrapper {
    width: 100%;
    margin-top: 40px;
    margin-bottom: 100px;
}

.program .scroll {
    text-decoration: underline;
    margin-left: 10px;
    margin-right: 10px;
}

.program .scroll:hover {
    cursor: pointer;
}

