.rsvp {
    background-image: url(../../images/rsvp/bg65.png);
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center center;
    background-size: cover;
}

.rsvp-button {
    background-color: #302F2F;
    padding: 5px 15px;
    font-family: "GlacialIndifference", serif;
    font-size: 32px;
    color: white;
    width: 318px;
    transition: 0.3s;
    text-align: center;
    text-decoration: none;
}

.rsvp-wrapper {
    width: fit-content;
}

.rsvp-button:hover {
    cursor: pointer;
    opacity: 0.8;
}

.rsvp .title {
    text-align: left;
}


.rsvp-form-body{
    display: flex;
    flex-direction: row;
    margin: 0 10px;
}

.rsvp-row {
    margin-top: 5px;
    margin-bottom: 15px;
    height: 60px;
    display: flex;
    align-items: center;
}

.rsvp-form-labels {
    flex-direction: column;
    padding-right: 20px;
}

.rsvp-form-inputs {
    flex-direction: column;
    flex-grow: 1;
}

.rsvp label {
    text-align: left;
    font-family: "GlacialIndifference", serif;
    font-size: 20px;
    color: #302F2F;
}

.rsvp input {
    height: 100%;
    font-family: "GlacialIndifference", serif;
    font-size: 20px;
    color: #302F2F;
    width: 100%;
    box-sizing:border-box;
}

.rsvp-form {
    padding: 10px 0;
    background-color: white;
}



@media (max-width: 600px) {
    .rsvp-button {
        font-size: 20px;
    }
    .rsvp input, label {
        font-size: 20px;
    }

    .rsvp-row {
        height: 45px;
    }
}
