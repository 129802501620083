.gift {
    margin-top: 5REM;
}

.gift img {
    width: 100%;
}

.gift-header{
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
}

.gift-title{
    text-align: left;
}

.gift-price{
    text-align: right;
}

.gift-description {
    margin-bottom: 3REM;
    text-align: left;
}

.gift-info {
    margin-bottom: 3REM;
    text-align: left;
}

.gift-button {
    background-color: #302F2F;
    width: 100%;
    justify-content: center;
    align-content: center;
    padding-top: 5px;
    padding-bottom: 5px;
    font-family: "GlacialIndifference", serif;
    font-size: 32px;
    color: white;
    transition: 0.3s;
}

.gift-button:hover {
    cursor: pointer;
    opacity: 0.8;
}

.gift-form-body{
    display: flex;
    flex-direction: row;
    margin: 1REM 10px;
}

.gift-row {
    margin-top: 5px;
    margin-bottom: 5px;
    height: 60px;
    display: flex;
    align-items: center;
}

.gift-form-labels {
    flex-direction: column;
    padding-right: 20px;
}

.gift-form-inputs {
    flex-direction: column;
    flex-grow: 1;
}

.gift label {
    text-align: left;
    font-family: "GlacialIndifference", serif;
    font-size: 32px;
    color: #302F2F;
}

.gift input {
    height: 100%;
    font-family: "GlacialIndifference", serif;
    font-size: 32px;
    color: #302F2F;
    width: 100%;
    box-sizing:border-box;
}

.gift-image{
    position: relative;
}

.gift-index{
    position: absolute;
    font-family: "GlacialIndifference", serif;
    color: white;
    width: fit-content;
    font-size: 64px;
    font-weight: bold;
    left: 20px;
    top: 10px;
}

.gift-index-triangle {
    position: absolute;
    width: 0;
    height: 0;
    left: 0;
    top: 0;
    border-top: 200px solid rgba(48,47,47,0.5);
    border-right: 200px solid transparent;
}

@media (max-width: 600px) {
    .gift input, .gift label, .gift-button {
        font-size: 20px;
    }
    .gift-row {
        height: 40px;
    }

    .gift-index{
        left: 10px;
        top: 5px;
        font-size: 32px;
    }

    .gift-index-triangle {
        border-top: 100px solid rgba(48,47,47,0.5);
        border-right: 100px solid transparent;
    }
}
