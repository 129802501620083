.couple {
    background-color: white;
}

.story-image {
    margin-top: 2rem;
    margin-bottom: 3rem;
}

.couple p,h2 {
    text-align: left;
}