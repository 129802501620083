.form-screen .form{
    display: flex;
    flex-direction: column;
}

.form-screen .form > *{
    margin-bottom: 30px;
}

.form-screen .form *{
    font-size: 32px;
}


.form-screen .form:last-child{
    margin-bottom: 0;
}

.form-row{
    display: flex;
    flex-direction: row;
}

.form-row.stay{
    flex-direction: row;
}

.form-row.col{
    flex-direction: column;
}

.form-row.col > *{
    margin-bottom: 10px;
}

.form-row.col:last-child{
    margin-bottom: 0;
}

.form-row.col .form-input{
}

.form-row label{
    flex-shrink: 0;
    width: 205px;
    text-align: left;
    font-family: "GlacialIndifference", serif;
    /*font-size: 32px;*/
    color: #302F2F;
}

.form-row .form-input{
    flex-shrink: 1;
    flex-grow: 1;
    text-align: left;
}
.guest-email{
    width: 100%;
    height: 40px;
    font-family: "GlacialIndifference", serif;
    /*font-size: 32px;*/
    color: #302F2F;
    box-sizing: border-box;
}

.form-input select.guest-present {
    height: 40px;
    font-family: "GlacialIndifference", serif;
    font-size: 25px;
    color: #302F2F;
    box-sizing: border-box;
    width: 160px;
}

.form-input select.guest-present option{
    font-family: "GlacialIndifference", serif;
    font-size: 25px;
}

.guest-remarks{
    width: 100%;
    height: 160px;
    font-family: "GlacialIndifference", serif;
    /*font-size: 32px;*/
    color: #302F2F;
    box-sizing: border-box;
}


.form-input input[type="checkbox"]{
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.submit-button {
    margin-top: 30px;
    background-color: #302F2F;
    padding: 5px 15px;
    font-family: "GlacialIndifference", serif;
    /*font-size: 32px;*/
    color: white;
    width: 180px;
    transition: 0.1s;
    text-align: center;
    opacity: 1;
    user-select: none;
}

.submit-button:hover{
    opacity: 0.8;
    cursor: pointer;
}

.form-error{
    font-family: "GlacialIndifference", serif;
    /*font-size: 32px;*/
    color: darkred;
}

@media (max-width: 700px) {
    .form-row{
        flex-direction: column;
    }

    .form-row > *{
        margin-bottom: 10px;
    }

    .form-row:last-child{
        margin-bottom: 0;
    }

    .form-row.stay{
        column-gap: 20px;
    }

    .form-row label{
        flex-grow: 0;
        flex-shrink: 1;
        width: unset;
    }

}

@media (max-width: 500px) {

    .form-screen .form *{
        font-size: 20px;
    }

    .form-screen .form .form-input select{
        font-size: 14px;
        height: 30px;
    }

    .form-screen .form .form-input select option{
        font-size: 14px;
    }

    .form-screen .form .form-input .guest-sleepover{
        width: 70px;
    }

    .form-screen .form .form-input .guest-present{
        width: 110px;
    }
}

