.js-nav-push {
    width: 100vw;
    height: 5rem;
}

.js-nav {
    width: 100%;
    height: 5rem;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 1000;
    background-color: #302F2F;
}

@media
(min-width: 856px) {
    .js-nav ul {
        list-style-type: none;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin: 0;
        padding: 0;
    }

    .js-nav {
        min-width: 600px;
    }

    .js-nav-hamburger {
        display: none;
    }

}

@media
(max-width: 856px) {

    .js-nav {
        min-width: 320px;
    }

    .js-nav ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
    }

    .js-nav ul li {
        padding: 15px;
    }

    .js-nav-hamburger {
        display: flex;
        height: 100%;
        align-items: center;
        margin-left: 15px;
        user-select: none;
    }

    .js-nav-hamburger:hover {
        cursor: pointer;
        opacity: 0.8;
    }


    .js-nav div.js-nav-closed{
        display: none;
    }

    .js-nav div.js-nav-open{
        width: 100%;
        display: block;
        background-color: rgba(0, 0, 0, 0.8);
    }

    .js-nav {
        min-width: 320px;
    }
}
