.guest-item {
    display: flex;
    flex-direction: row;
}

.guest-item > *{
    margin-left: 25px;
}

.guest-item:last-child{
    margin-left: 0;
}

.guest-item .form {
    flex-grow: 1;
    border-left: 4px solid #302F2F;
    padding-left: 20px;
}

.guest-name {
    width: 100%;
    height: 40px;
    font-family: "GlacialIndifference", serif;
    font-size: 32px;
    color: #302F2F;
    box-sizing: border-box;
}

.form-input select.guest-sleepover {
    width: 100px;
    height: 40px;
    font-family: "GlacialIndifference", serif;
    font-size: 25px;
    color: #302F2F;
    box-sizing: border-box;
}

select:hover{
    cursor: pointer;
}

option:hover{
    cursor: pointer;
}

.form-input select.guest-sleepover option {
    font-family: "GlacialIndifference", serif;
    font-size: 25px;
}

.guest-diet{
    width: 100%;
    height: 120px;
    font-family: "GlacialIndifference", serif;
    font-size: 32px;
    color: #302F2F;
    box-sizing: border-box;
}


.guest-remove{
    width: 64px;
    display: flex;
    align-items: center;
    justify-content: end;
    border-left: 4px solid #302F2F;
}

.add-button{
    margin-top: 30px;
    background-color: #302F2F;
    padding: 5px 15px;
    font-family: "GlacialIndifference", serif;
    font-size: 32px;
    color: white;
    width: 220px;
    transition: 0.1s;
    text-align: center;
    opacity: 1;
    margin-left: auto;
    margin-right: auto;
    user-select: none;
}

.add-button:hover{
    opacity: 0.8;
    cursor: pointer;
}

@media (max-width: 500px) {

    .guest-item > *{
        margin-left: 10px;
    }

    .guest-remove{
        width: 48px;
    }

}

