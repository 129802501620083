.inner-screen {
    padding-top: 2rem;
    padding-left: 1.2rem;
    padding-right: 1.2rem;
    display: flex;
    justify-content: center;
    text-align: center;
}

.inner-screen-wrapper{
    width: 100%;
}

@media
(min-width: 800px) {
    .inner-screen {
        padding-left: 6rem;
        padding-right: 6rem;
    }
}
