.js-nav-link {
    color: white;
    text-decoration: none;
    font-family: "GlacialIndifference", serif;
    font-size: 36px;
}

.js-nav-link:hover{
    color: white;
}

.js-nav-link-active{
    text-decoration: underline;
}

@media (max-width: 600px) {
    .js-nav-link {
        font-size: 26px;
    }
}