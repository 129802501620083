* {
    padding: 0;
    margin: 0;
}

body {
    margin: 0;
    overflow-y: scroll;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@font-face {
    font-family: PlaylistScript;
    src: url(fonts/playlist_script.otf) format("opentype");
}

@font-face {
    font-family: GlacialIndifference;
    src: url(fonts/GlacialIndifference-Regular.otf) format("opentype");
}

@font-face {
    font-family: GlacialIndifference;
    src: url(fonts/GlacialIndifference-Bold.otf) format("opentype");
    font-weight: bold
}

@font-face {
    font-family: GlacialIndifference;
    src: url(fonts/GlacialIndifference-Italic.otf) format("opentype");
    font-style: italic;

}

h1 {
    font-family: "PlaylistScript", serif;
    color: #302F2F;
    font-size: 100px;
    font-weight: 100;
}

h2 {
    font-family: "GlacialIndifference", serif;
    font-weight: bold;
    text-transform: uppercase;
    color: #302F2F;
    font-size: 32px;
    margin-bottom: 32px;
}

h3 {
    font-family: "GlacialIndifference", serif;
    color: #302F2F;
    font-size: 32px;
    font-weight: 100;
    margin-bottom: 32px;
}

p {
    font-family: "GlacialIndifference", serif;
    font-size: 32px;
    color: #302F2F;
}

@media (max-width: 600px) {
    p, h3, h2 {
        font-size: 20px;
    }
    h1 {
        font-size: 65px;
    }
}

.no-select {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}


