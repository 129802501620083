.guest-list {
    display: flex;
    flex-direction: column;
}

.guest-list > *{
    margin-bottom: 40px;
}

.guest-list:last-child{
    margin-bottom: 0;
}

.guest-list > hr:last-child{
    margin-bottom: 0;
}
