.program-item {
    display: flex;
    flex-direction: row;
    align-content: start;
    text-align: start;
    padding-bottom: 3rem;
}

.program-item-image {
    align-self: center;
    margin-right: 1.5rem;
    width: 65px;
    height: 65px;
}

.program-item-time {
    padding: 0;
    margin: 0;
}

.program-item-description {
    padding: 0;
    margin: 0;
    font-size: 30px;
}

@media (max-width: 600px) {
    .program-item-description {
        font-size: 22px;
    }

    .program-item-image {
        width: 45px;
        height: 45px;
    }
}