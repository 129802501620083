.contact {
    background-color: white;
}

.eelke-image{
    margin-top: 3rem;
    margin-bottom: 1rem;
}

.contact h5 {
    font-family: "GlacialIndifference", serif;
    font-size: 32px;
    color: #302F2F;
    font-weight: 100;
}

@media (max-width: 600px) {
    .contact h3 {
        font-size: 20px;
    }
}